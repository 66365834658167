<script src="../../plugins/keycloak.js"></script>
<template>
  <div class="row my-5 bg-banner">
    <div class="col-lg-5 pt-lg-3 mx-3 my-5">
      <h1 class="display-4 fw-bold lh-1">{{prismic['hero-image-title'][0].text}}</h1>
      <p v-if="canCreateSpace()" class="lead my-3">
        {{ prismic['hero-image-sub-text'][0].text }}
      </p>
      <div v-if="canCreateSpace()" class="d-grid gap-2 d-md-flex justify-content-md-start mb-4 mb-lg-3">
        <router-link
          :to="'/'+prismic['hero-link']"
          class="btn btn-md btn-rounded px-4 me-md-2 btn-dark my-1"
        >{{prismic['hero-link-text']}}</router-link>
      </div>
    </div>
    <div class="col-lg-4 offset-lg-1 p-0 overflow-hidden"></div>
  </div>
</template>

<script>
import { keycloak } from "../../plugins/keycloak";
export default {
  name: 'Banner',
  // components: { kc },
  props: {
    prismic: {
      type: Object,
      // kc: keycloak,
    },
  },
  methods : {
    canCreateSpace(){
      return keycloak.idTokenParsed.realm_access.roles.includes("create_space")
    },
  },
};
</script>
