<template>
  <section>
    <Banner v-if="isReady" :prismic="prismicDocument" />
    <div class="row gx-5 gy-3 border-top pt-4 pb-5">
      <launch-card
        v-for="(c, i) of cards"
        :key="i"
        :userCount="c.users"
        :title="c.spaceName"
        :link="getLink(c)"
        @click="launchProject(c)"
      />
    </div>
  </section>
</template>

<script>
import Banner from '../components/Home/Banner.vue';
import LaunchCard from '../components/Home/LaunchCard.vue';
export default {
  components: { LaunchCard, Banner },
  name: 'Home',
  props: {},
  data() {
    return {
      cards: [],
      isReady: false,
      prismicDocument: {},
    };
  },
  async created() {
    const res = await this.$http.get('/spaces');
    if (res && res.data && res.data.results) {
      this.cards = res.data.results;
    }
  },
  methods: {
    getLink(c) {
      return c.spaceName + '.ziqni.app';
    },
    launchProject(c) {
      window.open('//' + this.getLink(c), '_self');
    },
    setSiteTitle(title){
      document.title = title
    },
  },
  beforeMount() {
    this.$prismic.client.query(
        [
          this.$prismic.Predicates.any("document.type", ["launchpad_home"]),
          this.$prismic.Predicates.any("document.tags", ["ziqni.app"]),
        ],
        null,
        (error, document) => {
          if (error) {
            console.error(`Received error ${error}`);
            return;
          }
          this.prismicDocument = document.results[0].data
          if(this.prismicDocument["site-title"] && this.prismicDocument["site-title"][0])
            this.setSiteTitle(this.prismicDocument["site-title"][0].text)
          this.isReady = true
        }
    )
  },
};
</script>
