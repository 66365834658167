<template>
  <div class="col-sm-6 col-lg-4 mb-4">
    <div class="card launchpad-card shadow-sm">
      <div class="card-body">
        <div class="card-title border-bottom text-right">
          <div class="card-icon" :id="'_id' + title"></div>

          <span><img src="@/assets/img/users.svg" /> {{ userCount }} USER</span>
        </div>
        <p class="card-text">{{ title }}</p>
        <p class="card-text">
          <small class="text-muted">{{ link }}</small>
        </p>
        <button
          type="button"
          class="
            btn btn-sm btn-rounded
            px-4
            me-md-2
            fw-bold
            btn-dark
            float-right
          "
          @click="$emit('click', $event)"
        >
          {{ btnText }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
export default {
  name: 'LaunchCardComponent',
  props: {
    userCount: {
      type: String,
      default: '1',
    },
    title: {
      type: String,
    },
    link: {
      type: String,
    },
    btnText: {
      type: String,
      default: 'Launch',
    },
  },
  computed: {},
  methods: {
    getHashIcon(ref, params = { size: 40 }) {
      const hash = '0xdc53525847b67a9e32d80066202d5744c86ae500';
      const icon = hashicon(hash + '' + this.title, params);
      if (ref) {
        document.getElementById(ref).appendChild(icon);
      }
    },
  },
  mounted() {
    this.getHashIcon('_id' + this.title);
  },
};
</script>
